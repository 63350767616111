@import './variables';

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: local(''), url('/fonts/poppins-v15-latin-regular.woff2') format('woff2');
}

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: local(''), url('/fonts/poppins-v15-latin-600.woff2') format('woff2');
}

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: local(''), url('/fonts/poppins-v15-latin-700.woff2') format('woff2');
}

/* darker-grotesque-500 - latin */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: local(''),
    url('/fonts/darker-grotesque-v2-latin-500.woff2') format('woff2');
}

/* darker-grotesque-700 - latin */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: local(''),
    url('/fonts/darker-grotesque-v2-latin-700.woff2') format('woff2');
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

html {
  color: $color-dark;
  box-sizing: border-box;
  font-size: 20px;
}

body.routing {
  opacity: 0.5;
  transition: opacity 100ms ease-in-out;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

main {
  flex: 1 0 auto;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

s::before,
s::after {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

s::before {
  content: ' [start of stricken text] ';
}

s::after {
  content: ' [end of stricken text] ';
}
