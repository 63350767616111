@import 'src/styles/mixins';

.default {
  padding: 0 0 0 24px;
  margin-top: 16px;
  list-style-type: none;
  li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -16px;
      top: 0.5em;
      width: 8px;
      height: 8px;
      background-color: currentColor;
      border-radius: 50%;
    }
  }
}

.orderedList {
  padding: '0 0 0 24px';

  li {
    @include basicText;
  }
}

.basicListItem {
  @include basicText;

  &:not(:first-of-type) {
    margin-top: 12px;
  }
}
