/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Poppins Fallback';src: local("Arial");ascent-override: 93.62%;descent-override: 31.21%;line-gap-override: 8.92%;size-adjust: 112.16%
}.__className_5d6309 {font-family: 'Poppins', 'Poppins Fallback';font-style: normal
}.__variable_5d6309 {--poppins-font: 'Poppins', 'Poppins Fallback'
}

/* vietnamese */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/057583820dbfe2d1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4f009f683e30da2d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/94b45cb581d25b69-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/057583820dbfe2d1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4f009f683e30da2d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/94b45cb581d25b69-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Darker Grotesque Fallback';src: local("Arial");ascent-override: 130.90%;descent-override: 36.55%;line-gap-override: 0.00%;size-adjust: 80.98%
}.__className_c7699f {font-family: 'Darker Grotesque', 'Darker Grotesque Fallback';font-style: normal
}.__variable_c7699f {--darker-font: 'Darker Grotesque', 'Darker Grotesque Fallback'
}

.text_h1__tLs9g{font-size:32px;line-height:1.3;font-weight:700;margin:.67em 0}@media(min-width: 900px){.text_h1__tLs9g{font-size:48px}}@media(min-width: 1200px){.text_h1__tLs9g{font-size:60px}}.text_h2__BJu0w{font-size:28px;line-height:1.3;font-weight:900;margin:.83em 0}@media(min-width: 900px){.text_h2__BJu0w{font-size:32px}}@media(min-width: 1200px){.text_h2__BJu0w{font-size:38px}}.text_h3__8RGWo{font-size:20px;font-weight:500;margin-top:0;margin-bottom:1rem;text-transform:uppercase;line-height:1.3}@media(min-width: 900px){.text_h3__8RGWo{font-size:24px}}@media(min-width: 1200px){.text_h3__8RGWo{font-size:28px}}.text_h4__SxyFx{font-size:16px;font-weight:900;margin-top:0;margin-bottom:.75rem;text-transform:uppercase}@media(min-width: 900px){.text_h4__SxyFx{font-size:18px}}@media(min-width: 1200px){.text_h4__SxyFx{font-size:20px}}.text_bodyText__ZHGcY{line-height:28px;font-size:24px;font-weight:500;margin:16px 0}.text_bodyText__ZHGcY a{color:#1e2e28;-webkit-text-decoration-line:underline;text-decoration-line:underline;-webkit-text-decoration-style:solid;text-decoration-style:solid;-webkit-text-decoration-color:#1e2e28;text-decoration-color:#1e2e28;text-decoration-thickness:1px}.text_bodyText__ZHGcY a:hover{color:#de8069;-webkit-text-decoration-color:#de8069;text-decoration-color:#de8069;text-decoration-thickness:3px}.text_introText__JzP_w{font-size:24px;line-height:1.3;margin:24px 0}@media(min-width: 900px){.text_introText__JzP_w{font-size:32px;line-height:1.2;margin:32px 0}}.text_introText__JzP_w.text_center__QNMeK{text-align:center}.text_em__wfezs{font-weight:700;font-style:italic}.text_small__EQ_6Z{font-size:16px}
:root {
  --reach-combobox: 1;
}

[data-reach-combobox-popover] {
  border: solid 1px hsla(0, 0%, 0%, 0.25);
  background: hsla(0, 100%, 100%, 0.99);
  font-size: 85%;
}

[data-reach-combobox-list] {
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

[data-reach-combobox-option] {
  cursor: pointer;
  margin: 0;
  padding: 0.25rem 0.5rem;
}

[data-reach-combobox-option][aria-selected="true"] {
  background: hsl(211, 10%, 95%);
}

[data-reach-combobox-option]:hover {
  background: hsl(211, 10%, 92%);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
  background: hsl(211, 10%, 90%);
}

[data-suggested-value] {
  font-weight: bold;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ffda05;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 5px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ffda05, 0 0 5px #ffda05;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/***
    The new CSS reset - version 1.11.3 (last updated 25.08.2024)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
    - The "html" element is excluded, otherwise a bug in Chrome breaks the CSS hyphens property (https://github.com/elad2412/the-new-css-reset/issues/36)
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Fix mobile Safari increase font-size on landscape mode */
html {
    text-size-adjust: none;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
    cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu, summary {
    list-style: none;
}

/* Firefox: solve issue where nested ordered lists continue numbering from parent (https://bugzilla.mozilla.org/show_bug.cgi?id=1881517) */
ol {
    counter-reset: revert;
}

/* For images to not be able to exceed their container */
img {
    max-inline-size: 100%;
    max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
    -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    -webkit-appearance: revert;
    -moz-appearance: revert;
         appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
    all: revert;
    box-sizing: border-box;
}

/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
    -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
    all: revert;
    box-sizing: border-box;
}

/* Remove details summary webkit styles */
::-webkit-details-marker {
    display: none;
}

@font-face{font-family:"Poppins";font-style:normal;font-weight:400;font-display:optional;src:local(""),url("/fonts/poppins-v15-latin-regular.woff2") format("woff2")}@font-face{font-family:"Poppins";font-style:normal;font-weight:600;font-display:optional;src:local(""),url("/fonts/poppins-v15-latin-600.woff2") format("woff2")}@font-face{font-family:"Poppins";font-style:normal;font-weight:700;font-display:optional;src:local(""),url("/fonts/poppins-v15-latin-700.woff2") format("woff2")}@font-face{font-family:"Darker Grotesque";font-style:normal;font-weight:500;font-display:optional;src:local(""),url("/fonts/darker-grotesque-v2-latin-500.woff2") format("woff2")}@font-face{font-family:"Darker Grotesque";font-style:normal;font-weight:700;font-display:optional;src:local(""),url("/fonts/darker-grotesque-v2-latin-700.woff2") format("woff2")}#__next{min-height:100vh;display:flex;flex-direction:column;justify-content:space-between}html{color:#1e2e28;box-sizing:border-box;font-size:20px}body.routing{opacity:.5;transition:opacity 100ms ease-in-out}*,*:before,*:after{box-sizing:inherit}main{flex:1 0 auto}strong{font-weight:700}img{max-width:100%;height:auto}a,button{-webkit-tap-highlight-color:rgba(0,0,0,0)}s::before,s::after{-webkit-clip-path:inset(100%);clip-path:inset(100%);clip:rect(1px, 1px, 1px, 1px);height:1px;overflow:hidden;position:absolute;white-space:nowrap;width:1px}s::before{content:" [start of stricken text] "}s::after{content:" [end of stricken text] "}
.list_default__B7YkN{padding:0 0 0 24px;margin-top:16px;list-style-type:none}.list_default__B7YkN li{position:relative}.list_default__B7YkN li:before{content:"";position:absolute;left:-16px;top:.5em;width:8px;height:8px;background-color:currentColor;border-radius:50%}.list_orderedList__g9L4i{padding:"0 0 0 24px"}.list_orderedList__g9L4i li{line-height:28px;font-size:24px;font-weight:500}.list_orderedList__g9L4i li a{color:#1e2e28;-webkit-text-decoration-line:underline;text-decoration-line:underline;-webkit-text-decoration-style:solid;text-decoration-style:solid;-webkit-text-decoration-color:#1e2e28;text-decoration-color:#1e2e28;text-decoration-thickness:1px}.list_orderedList__g9L4i li a:hover{color:#de8069;-webkit-text-decoration-color:#de8069;text-decoration-color:#de8069;text-decoration-thickness:3px}.list_basicListItem__qz8Rf{line-height:28px;font-size:24px;font-weight:500}.list_basicListItem__qz8Rf a{color:#1e2e28;-webkit-text-decoration-line:underline;text-decoration-line:underline;-webkit-text-decoration-style:solid;text-decoration-style:solid;-webkit-text-decoration-color:#1e2e28;text-decoration-color:#1e2e28;text-decoration-thickness:1px}.list_basicListItem__qz8Rf a:hover{color:#de8069;-webkit-text-decoration-color:#de8069;text-decoration-color:#de8069;text-decoration-thickness:3px}.list_basicListItem__qz8Rf:not(:first-of-type){margin-top:12px}
.image_imageNode__9mBTp{border-radius:4px}
