@import './variables';

@mixin baseGrid {
  width: calc(100vw - 48px);
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $bp1) {
    width: calc(100vw - 80px);
  }

  @media (min-width: 1280px) {
    width: 1200px;
  }
}

@mixin basicText {
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;

  a {
    color: $color-dark;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: $color-dark;
    text-decoration-thickness: 1px;

    &:hover {
      color: $color-teja;
      text-decoration-color: $color-teja;
      text-decoration-thickness: 3px;
    }
  }
}

@mixin badge {
  display: block;
  width: fit-content;
  padding: 4px 8px;
  font-family: var(--poppins-font);
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
}

@mixin baseInputStyles {
  border: 1px solid $color-gray;
  background-color: transparent;
  border-radius: 4px;
  color: $color-dark;
  padding: 12px 12px 14px;
  outline: none;
  width: 100%;
  margin-top: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  --webkit-appearance: none;

  &:focus {
    border-color: $color-dark;
    box-shadow: 0px 0px 0px 1px $color-potus;
  }

  ::placeholder {
    font-weight: 700;
    font-size: 14px;
  }
}

@mixin iconButton($size, $color: $color-dark) {
  padding: 0;
  border: none;
  background-color: transparent;
  width: $size;
  height: $size;
  color: $color;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@mixin linkInline {
  text-decoration: none;
  color: $color-dark;
  border-bottom: 1px solid currentColor;

  &:hover {
    color: $color-teja;
  }
}

@mixin button($is-primary: true, $font-size: 16px, $is-small: false) {
  font-family: var(--poppins-font);
  color: $color-dark;
  border-radius: 4px;
  border: none;
  font-size: $font-size;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  cursor: pointer;
  transition: all 0.075s ease-in-out;

  @if $is-small {
    padding: 4px 8px;
  } @else {
    padding: 16px 24px;
  }

  @if $is-primary {
    background-color: $color-attention;

    &:hover {
      background-color: $color-attentionHover;
    }

    &:focus {
      box-shadow: 0 0 0 5px $color-white, 0 0 2px 7px $color-attention;
      outline: none;
    }

    &:disabled {
      background-color: $color-lightGrey;
      cursor: not-allowed;
    }
  } @else {
    background-color: $color-gray0;

    &:hover {
      background-color: $color-gray0Hover;
    }

    &:focus {
      box-shadow: 0 0 0 5px $color-white, 0 0 2px 7px $color-gray0;
      outline: none;
    }
  }
}

@mixin badge {
  display: block;
  width: fit-content;
  padding: 4px 8px;
  font-family: var(--poppins-font);
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
}

@mixin textBadge($font-size: 14px) {
  @include badge;
  background-color: $color-potus;
  letter-spacing: 0.2rem;
  color: $color-white;
  text-transform: uppercase;
  font-size: $font-size;
}

@mixin isVisuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}
