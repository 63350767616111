@import 'src/styles/variables';

.h1 {
  font-size: 32px;
  line-height: 1.3;
  font-weight: 700;
  margin: 0.67em 0;

  @media (min-width: $bp2) {
    font-size: 48px;
  }

  @media (min-width: $bp3) {
    font-size: 60px;
  }
}

.h2 {
  font-size: 28px;
  line-height: 1.3;
  font-weight: 900;
  margin: 0.83em 0;

  @media (min-width: $bp2) {
    font-size: 32px;
  }

  @media (min-width: $bp3) {
    font-size: 38px;
  }
}

.h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  line-height: 1.3;

  @media (min-width: $bp2) {
    font-size: 24px;
  }

  @media (min-width: $bp3) {
    font-size: 28px;
  }
}

.h4 {
  font-size: 16px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0.75rem;
  text-transform: uppercase;

  @media (min-width: $bp2) {
    font-size: 18px;
  }

  @media (min-width: $bp3) {
    font-size: 20px;
  }
}

.bodyText {
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;
  margin: 16px 0;

  & a {
    color: $color-dark;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: $color-dark;
    text-decoration-thickness: 1px;

    &:hover {
      color: $color-teja;
      text-decoration-color: $color-teja;
      text-decoration-thickness: 3px;
    }
  }
}

.introText {
  font-size: 24px;
  line-height: 1.3;
  margin: 24px 0;

  @media (min-width: $bp2) {
    font-size: 32px;
    line-height: 1.2;
    margin: 32px 0;
  }

  &.center {
    text-align: center;
  }
}

.em {
  font-weight: 700;
  font-style: italic;
}

.small {
  font-size: 16px;
}
